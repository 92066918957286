import React, { useState } from "react";
import { Card, Col, Row, Table, Form, DatePicker, Select, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getCustomBusinessUnitForProfitLossStatement, getProfitLossStatementData, getCurrency } from "../../../services/generic";
import "./styles.css";
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

const ProfitLossStatement = () => {
  const [bunitData, setBunitData] = useState([]);
  const [mainRowData, setMainRowData] = useState([]);
  const [mainColumnData, setMainColumnData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currencyData, setCurrencyData] = useState([]);
  const [headerform] = Form.useForm();

  const getBusinessUnit = async () => {
    const businessUnitResponse = await getCustomBusinessUnitForProfitLossStatement();
    setBunitData(businessUnitResponse);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const valuesArray = Object.values(values);
    let valuesObj = {};
    valuesObj["bunitId"] = valuesArray[0];
    const [startDate, endDate] = valuesArray[1]?.map((date) => date.format("YYYY-MM-DD"));
    valuesObj["dateFrom"] = startDate;
    valuesObj["dateTo"] = endDate;

    const stringifiedJSON = JSON.stringify(valuesObj);
    const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');

    const serverResponse = await getProfitLossStatementData(jsonToSend);

    let currencyObj = {};
    currencyObj["bunitId"] = valuesArray[0];

    const stringifiedCurrencyJSON = JSON.stringify(currencyObj);
    const currencyJsonToSend = stringifiedCurrencyJSON.replace(/"/g, '\\"');

    const currencyResponse = await getCurrency(currencyJsonToSend);

    if (currencyResponse) {
      const currencyData = JSON.parse(currencyResponse.data.data.executeAPIBuilder);
      setCurrencyData(currencyData);
    }

    if (serverResponse) {
      const data = JSON.parse(serverResponse.data.data.executeAPIBuilder);

      // let formulaArray = [];
      // for (let index = 0; index < data.length; index++) {
      //   if (data[index].formula) {
      //     formulaArray.push(data[index].formula);
      //   }
      // }
      // for (let index1 = 0; index1 < formulaArray.length; index1++) {
      //   let splitArr1 = [];
      //   let splitSymArr1 = [];
      //   formulaArray[index1].split("(").forEach((element) => {
      //     if (element.indexOf(")") > -1) {
      //       splitArr1 = splitArr1.concat(element.split(")")[0]);
      //     }
      //   });
      //   formulaArray[index1].split(")").forEach((element) => {
      //     if (element.indexOf("(") > -1) {
      //       splitSymArr1 = splitSymArr1.concat(element.split("(")[0]);
      //     }
      //   });
      //   if (splitArr1.length > 1) {
      //     let sumArr = [splitArr1.length];
      //     for (let index2 = 0; index2 < splitArr1.length; index2++) {
      //       let valueArr1 = [];
      //       let childArr1 = [];
      //       for (let index3 = 0; index3 < data.length; index3++) {
      //         if (data[index3].parentaccount === splitArr1[index2]) {
      //           childArr1.push(data[index3].accountcode);
      //           valueArr1.push(data[index3].value);
      //         }
      //       }
      //       if (childArr1.length > 0) {
      //         for (let index4 = 0; index4 < childArr1.length; index4++) {
      //           for (let index5 = 0; index5 < data.length; index5++) {
      //             if (data[index5].parentaccount === childArr1[index4]) {
      //               childArr1.push(data[index5].accountcode);
      //               valueArr1.push(data[index5].value);
      //               childArr1.slice(1, 0);
      //             }
      //           }
      //         }
      //       }
      //       let sum = 0;
      //       for (let index6 = 0; index6 < valueArr1.length; index6++) {
      //         sum = sum + valueArr1[index6];
      //       }
      //       sumArr[index2] = sum;
      //     }
      //     let value = "undefined";
      //     for (let index7 = 0; index7 < sumArr.length - 1; index7++) {
      //       if (value === "undefined") {
      //         if (splitSymArr1[index7 + 1] === "+") {
      //           value = sumArr[index7] + sumArr[index7 + 1];
      //         }
      //         if (splitSymArr1[index7 + 1] === "-") {
      //           value = sumArr[index7] - sumArr[index7 + 1];
      //         }
      //         if (splitSymArr1[index7 + 1] === "*") {
      //           value = sumArr[index7] * sumArr[index7 + 1];
      //         }
      //       } else {
      //         if (splitSymArr1[index7 + 1] === "+") {
      //           value = value + sumArr[index7 + 1];
      //         }
      //         if (splitSymArr1[index7 + 1] === "-") {
      //           value = value - sumArr[index7 + 1];
      //         }
      //         if (splitSymArr1[index7 + 1] === "*") {
      //           value = value * sumArr[index7 + 1];
      //         }
      //       }
      //     }
      //     for (let index8 = 0; index8 < data.length; index8++) {
      //       if (data[index8].formula === formulaArray[index1]) {
      //         data[index8].value = value.toFixed(2);
      //       }
      //     }
      //   }
      // }

      // const toTree = (arr) => {
      //   for (let index = 0; index < arr.length; index++) {
      //     arr[index].key = index;
      //     if (arr[index].issummary === "Y" && arr[index].formula === null) {
      //       arr[index].value = "";
      //     }
      //   }
      //   const arrMap = new Map(arr.map((item) => [item.accountcode, item]));
      //   const tree = [];
      //   for (let index = 0; index < arr.length; index++) {
      //     const item = arr[index];
      //     if (item.parentaccount) {
      //       const parentItem = arrMap.get(item.parentaccount);
      //       if (parentItem) {
      //         const { children } = parentItem;
      //         if (children) {
      //           parentItem.children.push(item);
      //         } else {
      //           parentItem.children = [item];
      //         }
      //       }
      //     } else {
      //       tree.push(item);
      //     }
      //   }
      //   return tree;
      // };

      // const treeRowData = toTree(data);

      // Function to structure the data with "Income" and "Expense" headers
      const structureData = (data) => {
        const mainHeader = {
          account_type: "",
          amount: "Sub Total",
          catergorycode: "",
          acctnumber: "Account",
          category: "",
          acctname: "Particulars",
          total: "Total",
          type: "heading",
        };

        const incomeHeader = {
          account_type: "",
          amount: "",
          catergorycode: "",
          acctnumber: "",
          category: "",
          acctname: "Revenue",
          type: "main",
        };

        const expenseHeader = {
          account_type: "",
          amount: "",
          catergorycode: "",
          acctnumber: "",
          category: "",
          acctname: "Expense",
          show: "bold",
          type: "main",
        };

        // Separate RE (Income) and EX (Expense) data
        const incomeData = data.filter((item) => item.account_type === "RE");
        const expenseData = data.filter((item) => item.account_type === "EX");

        // Function to add dynamic records based on category
        const addDynamicRecords = (data) => {
          const result = [];
          let totalSum = 0; // Initialize the total sum for the categories

          // Group data by category
          const categories = [...new Set(data.map((item) => item.category))];

          categories.forEach((category) => {
            // Filter data for the current category
            const categoryData = data.filter((item) => item.category === category);
            const categorySum = categoryData.reduce((total, item) => total + parseFloat(item.amount || 0), 0);

            // Add to the overall total sum
            totalSum += categorySum;

            // Create dynamic record for this category
            const dynamicRecord = {
              account_type: categoryData[0].account_type,
              amount: "", // You can define an appropriate amount
              catergorycode: "",
              acctnumber: categoryData[0].catergorycode,
              category: "",
              acctname: categoryData[0].category,
              type: "category",
            };

            // Add the dynamic record before the category data
            result.push(dynamicRecord, ...categoryData);

            // Add a total row for this category
            const totalRecord = {
              account_type: "",
              amount: "", // Display the sum for the category
              catergorycode: "",
              acctnumber: "",
              category: "",
              acctname: `Total ${categoryData[0].category}`,
              total: categorySum,
              type: "subTotal",
            };

            result.push(totalRecord);
          });

          return { result, totalSum };
        };

        // Add dynamic records for income and expense data
        const { result: updatedIncomeData, totalSum: incomeTotalSum } = addDynamicRecords(incomeData);
        const { result: updatedExpenseData, totalSum: expenseTotalSum } = addDynamicRecords(expenseData);

        // Add the total row for Income and Expense
        const incomeTotalRecord = {
          account_type: "",
          amount: "", // Total sum for all Income categories
          catergorycode: "",
          acctnumber: "",
          category: "",
          acctname: "Total Revenue",
          total: incomeTotalSum,
          type: "total",
        };

        const expenseTotalRecord = {
          account_type: "",
          amount: "", // Total sum for all Expense categories
          catergorycode: "",
          acctnumber: "",
          category: "",
          acctname: "Total Expenses",
          total: expenseTotalSum,
          type: "total",
        };

        const netProfitLoss = incomeTotalSum - expenseTotalSum;

        const netProfitLossRecord = {
          account_type: "",
          amount: "",
          catergorycode: "",
          acctnumber: "",
          category: "",
          acctname: "Net Profit / Loss",
          total: netProfitLoss,
          type: "profit&loss",
        };

        // Combine headers with respective data and total records
        return [mainHeader, incomeHeader, ...updatedIncomeData, incomeTotalRecord, expenseHeader, ...updatedExpenseData, expenseTotalRecord, netProfitLossRecord];
      };

      const structuredData = structureData(data);

      const columns = [
        // {
        //     "title": "Parent Name",
        //     "dataIndex": "parentname",
        //     "key": "parentname"
        //   },
        //   {
        //     "title": "Parent Account",
        //     "dataIndex": "parentaccount",
        //     "key": "parentaccount",
        //   },
        // {
        //   title: "Account Type",
        //   dataIndex: "account_type",
        //   key: "account_type",
        // },
        {
          title: "Account Number",
          dataIndex: "acctnumber",
          key: "acctnumber",
          width: 40,
          ellipsis: true,
        },
        {
          title: "Account Name",
          dataIndex: "acctname",
          key: "acctname",
          render: (text, record) => {
            return record?.show === "bold" ? <strong>{text}</strong> : text;
          },
          width: 80,
          ellipsis: true,
        },
        {
          title: "Sub Total",
          dataIndex: "amount",
          key: "amount",
          render: (text, record) => {
            return <strong>{text ? parseFloat(text)?.toFixed(3) : ""}</strong>;
          },
          width: 40,
          ellipsis: true,
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          render: (text, record) => {
            return <strong>{text ? parseFloat(text)?.toFixed(3) : ""}</strong>;
          },
          width: 40,
          ellipsis: true,
        },
      ];
      setLoading(false);
      setMainRowData(structuredData);
      setMainColumnData(columns);
    }
  };

  const handleConfirm = () => {
    headerform.submit();
  };

  return (
    <div
      style={{
        overflowY: "scroll",
        height: "80vh",
        overflowX: "hidden",
      }}
    >
      <Row align="middle" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "0.4rem" }}>
        {/* Heading */}
        <Col span={8}>
          <h2
            style={{
              fontWeight: "700",
              fontSize: "1.3rem",
              color: "rgb(0 0 0 / 65%)",
              margin: 0,
              float: "left",
            }}
          >
            Profit & Loss Statement
          </h2>
        </Col>

        {/* Form */}
        <Col span={16} style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          {/* Business Unit Dropdown */}
          <Form layout="inline" form={headerform} onFinish={onFinish} style={{ flex: 1 }}>
            <Form.Item name="businessUnit" rules={[{ required: true, message: "Please select a business unit" }]} style={{ marginBottom: 0, flex: 1 }}>
              <Select
                allowClear
                showSearch
                placeholder="Select Business Unit"
                filterOption={(input, option) => option?.children?.toLowerCase().includes(input.toLowerCase())}
                onFocus={getBusinessUnit}
              >
                {bunitData.map((data) => (
                  <Option key={data.recordid} value={data.recordid} title={data.name}>
                    {data.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* Date Range Picker */}
            <Form.Item name="dateRange" rules={[{ required: true, message: "Please select a date range" }]} style={{ marginBottom: 0, flex: 1 }}>
              <DatePicker.RangePicker format={dateFormat} style={{ width: "100%" }} />
            </Form.Item>
          </Form>

          {/* View Button */}
          <Button
            style={{
              color: "white",
              width: "93px",
              height: "30px",
              fontFamily: "Roboto",
              fontWeight: 600,
              borderRadius: "5px",
            }}
            onClick={handleConfirm}
            type="primary"
          >
            View
          </Button>
        </Col>
      </Row>
      {/* <Row gutter={8}>
        <Col span={24} style={{ marginBottom: "8px" }}>
          <Card>
            <Row gutter={8}>
              <Col span={20}>
                <Form layout="vertical" form={headerform} onFinish={onFinish}>
                  <Row gutter={16}>
                    <Col className="gutter-row" span={6}>
                      <Form.Item name="businessUnit" label="Business unit" style={{ marginBottom: "8px" }} rules={[{ required: true }]}>
                        <Select allowClear showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} onFocus={getBusinessUnit}>
                          {bunitData.map((data) => (
                            <Option key={data.recordid} value={data.recordid} title={data.name}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={2.8}>
                      <Form.Item name="datefrom" label="From Date" style={{ marginBottom: "8px" }} rules={[{ required: true }]}>
                        <DatePicker format={dateFormat} />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={2.8}>
                      <Form.Item name="dateto" label="To Date" style={{ marginBottom: "8px" }} rules={[{ required: true }]}>
                        <DatePicker format={dateFormat} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col span={4}>
                <Button
                  style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px", float: "right", marginTop: "10%" }}
                  onClick={handleConfirm}
                >
                  View
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row> */}
      {/* <Table
        dataSource={mainRowData}
        columns={mainColumnData}
        pagination={false}
        style={{ padding: "0 15%" }}
        size="small"
        loading={{
          spinning: loading,
          indicator: <LoadingOutlined className="spinLoader" style={{ fontSize: "52px" }} spin />,
        }}
      /> */}
      <div className="tableContainer">
        <table>
          {/* <thead>
            <tr>
              <th>Particulars</th>
              <th>Amount (USD)</th>
            </tr>
          </thead> */}
          {/* <tbody>
            <tr>
              <th colspan="2">Revenue</th>
            </tr>
            <tr>
              <td>Sales Revenue</td>
              <td>100,000</td>
            </tr>
            <tr>
              <td>Other Income</td>
              <td>5,000</td>
            </tr>
            <tr className="totals-row">
              <td>Total Revenue</td>
              <td>105,000</td>
            </tr>
            <tr>
              <th colspan="2">Expenses</th>
            </tr>
            <tr>
              <td>Cost of Goods Sold (COGS)</td>
              <td>40,000</td>
            </tr>
            <tr>
              <td>Operating Expenses</td>
              <td>20,000</td>
            </tr>
            <tr>
              <td>Administrative Expenses</td>
              <td>10,000</td>
            </tr>
            <tr>
              <td>Depreciation</td>
              <td>2,000</td>
            </tr>
            <tr className="totals-row">
              <td>Total Expenses</td>
              <td>72,000</td>
            </tr>
            <tr>
              <th colspan="2">Net Profit</th>
            </tr>
            <tr className="net-profit">
              <td>
                <strong>Net Profit</strong>
              </td>
              <td>
                <strong>33,000</strong>
              </td>
            </tr>
          </tbody> */}
          <tbody>
            {mainRowData.map((row) => {
              if (row?.type !== "main") {
                if (row?.type === "heading") {
                  return (
                    <tr>
                      <th>{row?.acctnumber}</th>
                      <th>{row?.acctname}</th>
                      <th>{row?.amount}</th>
                      <th>{row?.total}</th>
                    </tr>
                  );
                } else {
                  return (
                    <tr>
                      <td
                        style={{
                          backgroundColor: row.type === "profit&loss" ? (row.total > 0 ? "#b2dea2" : "#d9a0b1") : "#ffffff",
                        }}
                      >
                        {row?.acctnumber}
                      </td>
                      <td
                        style={{
                          fontWeight: row?.type === "category" || row?.type === "subTotal" || row?.type === "profit&loss" || row?.type === "total" ? "bold" : "",
                          backgroundColor: row.type === "profit&loss" ? (row.total > 0 ? "#b2dea2" : "#d9a0b1") : "#ffffff",
                        }}
                        colSpan={row?.type === "category" ? "3" : row?.total ? "2" : "1"}
                      >
                        {row?.acctname}
                      </td>

                      {row?.total || row?.type === "category" ? null : (
                        <td
                          style={{
                            backgroundColor: row.type === "profit&loss" ? (row.amount > 0 ? "#b2dea2" : "#d9a0b1") : "#ffffff",
                          }}
                        >
                          {row?.amount ? currencyData[0]?.curr_symbol : ""} {row?.amount}
                        </td>
                      )}
                      {row?.type === "category" ? null : (
                        <td
                          style={{
                            fontWeight: "bold",
                            backgroundColor: row.type === "profit&loss" ? (row.total > 0 ? "#b2dea2" : "#d9a0b1") : "#ffffff",
                          }}
                        >
                          {row?.total ? currencyData[0]?.curr_symbol : ""} {row?.total?.toFixed(2)}
                        </td>
                      )}
                    </tr>
                  );
                }
              } else {
                return (
                  <tr>
                    <th>{row?.acctnumber}</th>
                    <th colspan="3">{row?.acctname}</th>
                    {/* <th>{row?.amount}</th>
                    <th>{row?.total}</th> */}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProfitLossStatement;
